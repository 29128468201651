



































import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'PublicLayout'
})
export default class PublicLayout extends Vue {}
