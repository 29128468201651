



























import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
const namespace = 'auth'

@Component({
  name: 'SignIn'
})
export default class SignIn extends Vue {
  // Redudant with how we're now handling auth due to onboarding package.
  @Action('getToken', { namespace }) getToken!: (token: string) => Promise<void>

  // Redudant with how we're now handling auth due to onboarding package.
  async handleAuth (): Promise<void> {
    await this.getToken('123465')
    await this.$router.push({ name: 'wallet' })
  }
}
